import { mapState, mapGetters, mapActions } from 'vuex'

// set your computed methods by mapping the state with data and setting our getters to call api;
export const statisticsComputed = {
    ...mapState('statistics', {
        statistics:(state) => state.statistics,
    }),
    ...mapGetters('statistics', ['getStatistics'])
}

// export statistics actions
export const statisticsMethods = mapActions('statistics', ['allStatistics',
])
